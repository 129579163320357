import React from "react"
import Layout from "../components/layout"

const About = () => (
<Layout>
  <div className="container">
    <div className="main">
    <div id="about">
    <h2>About<span className="text-yellow-400">.</span> <span className="h3">| SAKAIzmについて</span></h2>
    <div className="grid md:grid-cols-2 gap-4">
    <p>当サイトは、1999年3月に運営を開始した女優・坂井真紀さんのファンサイトです。坂井真紀さんの所属する株式会社スターダストプロモーションとは一切関係ありません。</p>
    <div>
    <div className="title"><h3>お問い合せ</h3></div>
    <p>ご質問・ご意見・ご感想等は以下のフォームよりお願いいたします。</p>
    <a href="https://airtable.com/shrzAYP2Qmr4YxtqN" rel="noopener noreferrer" target="_blank"><button className="border-yellow-400 hover:bg-yellow-400 btn" type="button">問い合わせる</button></a>
    </div>
    </div>
    </div>
    </div>
  </div>
</Layout>
)
export default About;